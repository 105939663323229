// linksDatabase.js
export const links = [
    { order: 1, title: 'To Inner Stillness/ Radio alHara', url: 'https://soundcloud.com/dreaming-live/mayss-for-asghar' },
    { order: 2, title: 'Mayss Live @ Kiosk Radio', url: 'https://www.youtube.com/watch?si=YYtnR7WQOMd2M5rr&v=UD1gUBKyRKQ&feature=youtu.be' },
    { order: 3, title: 'Mayss: On this Land (videoclip)', url: 'https://cdm.link/mayss-on-this-land/' },
    { order: 4, title: 'Bandcamp: ENOUGH! Fundraiser Comp for Dr Ghassan Abu Sittah Children Fund in Gaza', url: 'https://dreaminglive.bandcamp.com/album/enough' },
    { order: 8, title: 'Kiosk Outsiders: Mayss presents ENOUGH!', url: "https://soundcloud.com/kioskradio/outsiders-mayss-presents" },
    { order: 6, title: 'Dreaming Live with Mike Banks/ UR', url: 'https://soundcloud.com/dreaming-live/mikebanks' },
    { order: 7, title: 'Dreaming Live with Martin Rev/ Suicide', url: 'https://soundcloud.com/dreaming-live/rev' },
    { order: 8, title: 'Dreaming Live with Drew McDowall/ Coil', url: 'https://soundcloud.com/dreaming-live/rev' },


];
