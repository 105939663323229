import './Header.css';
import heart from '../../assets/graphics/toolbar/toolbar-star-n.png';
import backLanding from "../../assets/graphics/toolbar/toolbar-back-landing.png";
import tree from '../../assets/graphics/toolbar/tree.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isHeartRotated, setIsHeartRotated] = useState(false);
    const [isTreeRotated, setIsTreeRotated] = useState(false);
    const [historyStack, setHistoryStack] = useState([]); // Simulating history stack

    // Track the history of navigation
    useEffect(() => {
        setHistoryStack((prevStack) => [...prevStack, location.pathname]);
    }, [location.pathname]);

    const goBackTwoPages = () => {
        if (historyStack.length >= 2) {
            const twoPagesBack = historyStack[historyStack.length - 3];
            if (twoPagesBack && twoPagesBack.startsWith('/')) {
                navigate(twoPagesBack);
            } else {
                navigate('/dreams');
            }
        } else {
            navigate('/dreams');
        }
    };

    const handleBackClick = () => {
        if (location.pathname === '/dreams') {
            navigate("/");
        } else {
            navigate("/dreams");
        }
    };

    // Logic for the heart click (handling navigation to /about)
    const handleAboutClick = () => {
        if (location.pathname !== '/about') {
            setIsHeartRotated(true);
            navigate('/about');
        } else {
            // Check back navigation when already on /about
            const onePageBack = historyStack[historyStack.length - 2];
            const twoPagesBack = historyStack[historyStack.length - 3];

            if (onePageBack === '/tree') {
                if (twoPagesBack && twoPagesBack.startsWith('/')) {
                    navigate(twoPagesBack);
                } else {
                    navigate('/dreams');
                }
            } else if (onePageBack && onePageBack.startsWith('/')) {
                navigate(-1); // Go back one page if it's not /tree
            } else {
                navigate('/dreams');
            }
        }
    };

    // Logic for the tree click (handling navigation to /tree)
    const handleTreeClick = () => {
        if (location.pathname !== '/tree') {
            setIsTreeRotated(true);
            navigate('/tree');
        } else {
            // Check back navigation when already on /tree
            const onePageBack = historyStack[historyStack.length - 2];
            const twoPagesBack = historyStack[historyStack.length - 3];

            if (onePageBack === '/about') {
                if (twoPagesBack && twoPagesBack.startsWith('/')) {
                    navigate(twoPagesBack);
                } else {
                    navigate('/dreams');
                }
            } else if (onePageBack && onePageBack.startsWith('/')) {
                navigate(-1); // Go back one page if it's not /about
            } else {
                navigate('/dreams');
            }
        }
    };

    // Sync heart and tree rotation state based on URL
    useEffect(() => {
        if (location.pathname === '/about') {
            setIsHeartRotated(true);
        } else {
            setIsHeartRotated(false);
        }

        if (location.pathname === '/tree') {
            setIsTreeRotated(true);
        } else {
            setIsTreeRotated(false);
        }
    }, [location.pathname]);

    return (
        <header className="header">
            <button className="back" onClick={handleBackClick}>
                <img src={backLanding} alt="Back" className="backButton" />
            </button>
            <div className="right-bar">
                <button className={`tree ${isTreeRotated ? 'rotated' : ''}`} onClick={handleTreeClick}>
                    <img src={tree} alt="Tree" className="treeButton" />
                </button>
                <button className={`heart ${isHeartRotated ? 'rotated' : ''}`} onClick={handleAboutClick}>
                    <img src={heart} alt="about" className="heartButton" />
                </button>
            </div>
        </header>
    );
};

export default Header;
