import React, { useState, useEffect } from 'react';
import { episodes } from '../assets/data/dreamingLive';
import Thea from '../assets/graphics/theas/thea-podcast.png';
import './ListPodcast.css'; // Import the CSS for styling
import CloudTop from '../assets/graphics/cloud-footer.png';
import CloudsBottom from '../assets/graphics/cloud-down.png';

const ListPodcast = () => {
    const [episodesWithMeta, setEpisodesWithMeta] = useState([]);


    useEffect(() => {
        const fetchLinkMetadata = async () => {
            const fetchedEpisodes = await Promise.all(
                episodes.map(async (episode) => {
                    try {
                        const response = await fetch(`https://api.microlink.io?url=${episode.link}`);
                        const data = await response.json();
                        return {
                            ...episode,
                            thumbnail: data.data.image?.url || '', // Get the image from the Microlink response
                        };
                    } catch (error) {
                        console.error('Error fetching metadata:', error);
                        return episode; // Return the original episode data if there's an error
                    }
                })
            );
            setEpisodesWithMeta(fetchedEpisodes);
        };

        fetchLinkMetadata();
    }, []);

    return (
        <>
            <div className='container-dl'>
                <div className="thea">
                    <img src={Thea} alt="podcast" className="thea-image-dl" />
                    <h3>Dreaming Live</h3>

                    <div className="resultsPodcast">

                        {/* Pinned Section */}
                        <div className='resultsDiv'>
                            <p className='titleText'>
                                Dedicated to uncompromised creative expression.<br></br>
                                Art as the Voice of our Innermost, as opposed to Art as Entertainment.
                                <br></br>Dreaming Live is to Be, who we choose to Be.
                                <br></br>From our state of Being, to Dream.
                                <br></br>For Our Dreams, to Create.
                                <br></br>Lastly, against all odds, it is to Persist.
                            </p>
                        </div>


                        {/* All Episodes Section */}
                        <div className='results'>
                            {episodesWithMeta.map((episode) => (
                                <a href={episode.link} target="_blank" rel="noopener noreferrer" className="allEpisodesLink" key={episode.episode}>
                                    <div className='listEpisode'>
                                        {/* Thumbnail on top */}
                                        {episode.thumbnail && (
                                            <div className="episode-thumbnail">
                                                <img src={episode.thumbnail} alt={episode.title} className="episode-image" />
                                            </div>
                                        )}

                                        {/* Episode details */}
                                        <div className="episode-number">Episode {episode.episode.toString().padStart(2, '0')}</div>
                                        <div className="artist">{episode.artist}</div>
                                        <div className="episode-title">
                                            {episode.title}
                                            {episode.pinned && <span className='fav'>💖 Fav</span>}
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>

                    <div className='space-footer'></div>
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-top" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListPodcast;
