import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css'; // CSS for the landing page

const LandingPage = () => {
    const navigate = useNavigate(); // React Router hook for navigation

    const handleClick = () => {
        // Navigate to the Hobbies page
        navigate('/dreams');
    };

    return (
        <>
            <div className="landing-page" onClick={handleClick}>
            </div>
            {/* Make the text clickable and navigate to /dreams */}
            <h1 className="landing-page-text" onClick={handleClick}>
                Hello,
                <br /> I am Mayss,
                <br />
                Enter My
                <br /> Dream World >>
            </h1>
        </>
    );
};

export default LandingPage;
